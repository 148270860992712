import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { isValidUUID } from '@shared/utils';

import * as PermissionSelectors from '../store/selectors/permissions.selectors';
import * as PermissionActions from '../store/actions/permission.actions';

@Injectable({
  providedIn: 'root',
})
export class PrivilegesGuard {
  constructor(
    private store: Store,
    private actions$: Actions,
    private router: Router
  ) {}

  waitForDataToLoad(): Observable<boolean> {
    return this.store.pipe(
      select(PermissionSelectors.selectPrivilegesLoaded),
      filter((loaded) => loaded),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const uuid = route.paramMap.get('companyUuid') || route.paramMap.get('id');

    if (!uuid || !isValidUUID(uuid)) {
      return this.router.createUrlTree(['/404']);
    }

    this.store.dispatch(PermissionActions.loadPrivileges({ uuid }));
    return this.actions$.pipe(
      ofType(PermissionActions.loadPrivilegesSuccess, PermissionActions.loadPrivilegesFailure),
      map((action) => {
        if (action.type === '[Permissions/API] Load Privileges Success') {
          return true;
        } else {
          return this.router.createUrlTree(['/', 'dashboard'], {
            queryParams: { bureauId: uuid },
            queryParamsHandling: 'merge',
          });
        }
      })
    );
  }
}
